import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const IndexPage = () => (
  <Layout>
    <header
      id="up"
      class="bg-center bg-fixed bg-no-repeat bg-center bg-cover h-190 relative"
    >
      <div
        class="h-190 bg-opacity-50 bg-black flex items-center justify-center"
        style={{ background: "rgba(0,0,0,0.5)" }}
      >
        <section class=" text-gray-200 ">
          <div class="max-w-6xl mx-auto px-5 py-24 ">
            <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
              <h1 class=" title-font mb-2 text-3xl font-extrabold leading-10 tracking-tight text-center sm:text-5xl sm:leading-none md:text-7xl ">
                {" "}
                Sunday English Service
              </h1>
              <h1 class=" title-font mb-2 text-3xl font-extrabold leading-10 tracking-tight text-center sm:text-5xl sm:leading-none md:text-6xl p-5  text-white">
                {" "}
                10:00am-12:00pm
              </h1>
              <p class="lg:w-1/2 w-full leading-relaxed text-base  text-3xl">
                You and Your Family are Invited.
              </p>
            </div>
          </div>
        </section>
      </div>
    </header>

    <div class="px-6 py-2 ">
      <div class="container flex justify-between mx-auto">
        <div class="container overflow-x-hidden ">
          <div class="flex flex-wrap md items-center w-full">
            <div class="bg-white  xs:w-1/2 md:w-1/2 ">
              <div class="p-1">
                <h1 class="text-6xl font-bold mt-16 text-red-400 text-center">
                  Our location
                </h1>

                <div class="description w-full  md:w-100 mt-16 text-gray-500 text-2xl text-center">
                  We are at the junction of Karen Plains Road/Karen Road at the
                  Rhino House (in the ballroom of Academy of Dance) For Sunday
                  Service. During the week we are next door at Karen Plains
                  House 1st floor for the bible college and other church
                  activities. Click on the map to open.
                </div>
              </div>
            </div>
            <div class="  md:w-1/2  p-1 md:p-10">
              <a href="https://goo.gl/maps/gqnP9yAvh8D2">
                <StaticImage
                  src="../images/location.png"
                  className="rounded-md"
                  height={"100px"}
                  alt="Location of calvary chapel on google maps"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
